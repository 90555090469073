import Clipboard from 'clipboard';

$(document).on('turbolinks:load', function() {
  new Clipboard('.clipboard-btn')

  $('.mobile-nav-icon').click(function() {
    $('.navigation').slideToggle();
    $(this).toggleClass('mobile-nav-icon-active');
  });

  if ($('.video-list-tab h2.tab-heading').length > 0){
    Array.from($('.video-list-tab h2.tab-heading')).forEach(function(element, index){
      if ($(element).hasClass('open')){
        $(element).next().slideToggle().toggleClass('active-tab')
        $(element).toggleClass('active-tab')
      }

      if (location.hash && location.hash.split('#')[1] == $(element).parent().attr('id')){
        $(element).next().slideToggle().toggleClass('active-tab')
        $(element).toggleClass('active-tab')
      }
    })
  }

  if ($('.video-list-tab h2.tab-heading.committee').length === 0) { // exclude committee page
    $('.video-list-tab h2.tab-heading').click(function() {
      $(this).next().slideToggle().toggleClass('active-tab')
      $(this).toggleClass('active-tab')
    })
  }

  $('.video-button-wrapper a').click(function() {
    mixpanel.track("Watched Video", { "name": $(this).data("name"), "type": $(this).data("type") });
  })

  $('.close-reveal-modal').click(function(){
    var iframe = $(this).parent().children().find('iframe')
    var src = iframe.attr('src')
    iframe.attr('src', src)
  });

  $('.prescribing-resources-list a').click(function() {
    mixpanel.track("Prescribing Resource", {
      "id": $(this).closest('div').data("id"),
      "name": $(this).closest('div').data("name"),
      "pdf_file": $(this).closest('div').data("pdf"),
      "slug": $(this).closest('div').data("slug"),
      "site_type": $(this).closest('div').data("site_type")
    });
  })

  window.addEventListener("resize", function() {
    if (screen.width < 1000 && screen.height > 1000) {
      if ($('.mobile-interaction-link').css('display') === 'block') {
        $('.navigation').hide();
      } else {
        $('.navigation').show();
      }
    }
  }, false);

});
